@import "minima";

.note {
  color: #31708f;
  background-color: #d9edf7;
  border: 1px solid #bce8f1;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: $spacing-unit / 2;
}

.post-tags {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;

  li {
    background-color: #eee;
    border-radius: 4px;
    padding: 3px 6px;
    margin: 3px;

    a {
      color: #2a7ae2;
      text-decoration: none;
      font-size: 15px;
    }
  }
}
